<template>
  <div>
	<Header2022 :navbg='true'></Header2022>
    <div class="content">
      <div class="title">投诉建议</div>
      <div class="desc">
        您好，感谢您对小院生活的关注与支持。
      </div>
      <div class="information">
        如果您对我们的工作和服务有任何意见、建议，请填写以下内容进行反馈，您的反馈对我们非常重要。我们将万分感谢！
        为了使您的反馈得到及时回复和处理，请您务必完整填写以下信息。谢谢！
      </div>
      <div class="top type">
        <div class="key">类型</div>
        <div class="val">
          <el-radio v-model="type" label="1">投诉</el-radio>
          <el-radio v-model="type" label="2">建议</el-radio>
        </div>
      </div>
      <div class="top">
        <div class="key">姓名</div>
        <div class="val">
          <input type="text" name="name" placeholder="请输入您的姓名" v-model="name">
        </div>
        <div class="key">联系方式</div>
        <div class="val">
          <input type="text" name="mobile" placeholder="请输入您的联系方式" v-model="mobile">
        </div>
      </div>
      <div class="advice-title">
        请详细描述您的建议、意见、问题等：
      </div>
      <textarea name="advice" placeholder="您的意见与建议……" class="advice" v-model="advice"></textarea>
      <el-button type="primary" class="btn" @click="submit">提交</el-button>
    </div>
	<Footer2022></Footer2022>
  </div>
</template>

<script>
import Header2022 from '@/components/header2022.vue'
import Footer2022 from '@/components/footer2022.vue'
import utils from '@/common/utils'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      type:"1",
      name: "",
      mobile: "",
      advice: "",
    }
  },
  components: {
	Header2022,
	Footer2022,
  },
  computed: {
    ...mapState(['ocApi','user_info']),
  },
  created() {
    if(this.user_info && this.user_info.fullname){
      this.name = this.user_info.fullname
    }
    if(this.user_info && this.user_info.telephone){
      this.mobile = this.user_info.telephone
    }
  },
  methods: {
    submit: utils.debounce(function () {
      let that = this;
      if(!that.type){
        that.$message.error('请选择类型');
        return;
      }
      if(!that.name){
        that.$message.error('请输入姓名');
        return;
      }
      if(!utils.isMobile(that.mobile)){
        that.$message.error('请输入正确的手机号');
        return;
      }
      if(!that.advice){
        that.$message.error('请输入您的意见与建议');
        return;
      }
      that.$axios.post(that.ocApi + 'user/complain', {
        "complain_subject_content": that.type,
        "complain_name": that.name,
        "complain_phone": that.mobile,
        "complain_content": that.advice
      })
        .then(res => {
          if(res.data.code == 1){
            that.$message({
              type: 'success',
              message: '您的意见已提交!'
            });
            that.advice = ""
          }else {
            that.$message.error(res.data.message);
          }
        })

    }, 200, true),
  },
}
</script>

<style lang="scss" scoped>
.content {
  width: 900px;
  margin: auto;
  text-align: center;
  font-size: 16px;
  color: #595757;
}

.content .title {
  margin-top: 174px;
  font-size: 24px;
  color: #00cb87;
}

.content .desc {
  text-align: left;
  margin-top: 49px;
}

.content .information {
  text-align: left;
  text-indent: 32px;
}

.content .top {
  display: flex;
  height: 48px;
  margin-top: 26px;
  align-items: center;
}

.content .top .key {
  width: 65px;
  margin-right: 26px;
}

.content .top .val {
  flex: 1;
  height: 100%;
  text-align: left;
  display: flex;
  align-items: center;
}

.content .top div:nth-of-type(3) {
  margin-left: 29px;
}

.content .top .val input {
  background-color: #f7f7f7;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 16px 20px;
  font-size: 16px;
}

.content .advice-title {
  text-align: left;
  margin: 30px 0 26px 0;
}

.content .advice {
  width: 100%;
  height: 200px;
  padding: 11px 15px;
  background-color: #f7f7f7;
  border-radius: 10px;
  border: none;
}

.content .btn {
  width: 118px;
  margin: 40px auto 114px;
}
</style>